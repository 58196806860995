'use strict'

angular.module('cb.memo-detail-service', [])
	.factory('MemoDetailService', ["ApiService", function (ApiService) {

		var MemoDetailService = {
			getMemoById: function (id) {
				try {
					var url = '/webapi/api/v1/memo/viewMemo/' + id;
					return ApiService.promiseGet(url);
				} catch (e) {
					console.error('Error!', e);
				}
			},
			markMemoAsRead: function (data) {
				try {
					var url = '/webapi/api/v1/memo/markAsRead';
					return ApiService.promisePost(url, data);
				} catch (e) {
					console.error('Error!', e);
				}
			},
			deleteMemo: function (data) {
				try {
					var url = '/webapi/api/v1/memo/movetodelete';
					return ApiService.promisePost(url, data);
				} catch (e) {
					console.error('Error!', e);
				}
			},
			remindMe: function (data, id) {
				try {
					var url = '/webapi/api/v1/memo/remind/';
					return ApiService.promisePost(url, data)
				} catch (error) {
					console.error(error);
				}
			},

			replyToMemo: function (id, data) {
				try {
					var url = '';
					return ApiService.promisePost(url, id, data);
				} catch (e) {
					console.error('Error!', e)
				}
			},
			getMemoThread: function (id) {
				try {
					var url = '';
					return ApiService.promiseGet(url);
				} catch (e) {
					console.error('Error!', e)
				}
			},
			getMemos: function (mailBox, currentPage, pageSize) {
				try {
					var url = '/webapi/api/v1/memo/get' + mailBox + '/' + (currentPage -1) + '/' + pageSize; 
					return ApiService.promiseGet(url, currentPage, pageSize);
				} catch (e) {
					Console.error('Error!', e)
				}
			},
			getEmailNotifications: function (data) {
				try {
					var url = '/webapi/api/v1/memo/getemailstatus/';
					return ApiService.promisePost(url, data);

					return false;
				} catch (e) {
					Console.error('Error!', e)
				}
			},
			setEmailNotifications: function (data) {
				try {
					var url = '/webapi/api/v1/memo/setemailstatus';
					return ApiService.promisePost(url, data);

				} catch (e) {
					Console.error('Error!', e)
				}
			},
		};

		return MemoDetailService;
	}]);
